import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
// import { Location } from '@angular/common';
import { DataApi, Local, Constants, Opera, Config, FormatDate } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelStillMoreComponent } from '../model-still-more/model-still-more.component';
import { ModelSelectMoreComponent } from '../model-select-more/model-select-more.component';
import { ModelSelectTwoComponent } from '../model-select-two/model-select-two.component';
import { ModelPositionTemplateComponent } from '../model-position-template/model-position-template.component';
import { ConfirmsComponent } from "../confirms/confirms.component";
import { ConfirmComponent } from "../confirm/confirm.component";
import { MatDialog } from "@angular/material/dialog";
import { Events } from '../../provider/eventService';
import * as moment from 'moment';
declare let laydate;
declare var window;
@Component({
  selector: 'app-position-publish',
  templateUrl: './position-publish.component.html',
  styleUrls: ['./position-publish.component.css']
})
export class PositionPublishComponent implements OnInit {

  public config: Config;
  @ViewChild('child1', { static: true }) child: ModelStillMoreComponent;
  @ViewChild('child2', { static: true }) child2: ModelSelectMoreComponent;
  @ViewChild('child3', { static: true }) child3: ModelSelectTwoComponent;
  @ViewChild('child4', { static: true }) child4: ModelPositionTemplateComponent;
  @Output() modelShow = false;//是否打开弹窗
  @Output() modelTwoShow = false;//是否打开弹窗
  @Output() modelThreeShow = false;//是否打开弹窗
  @Output() modelFourShow = false;//是否打开弹窗
  public model = {
    type: '05',
    name: '',//职位名称
    post_type: '',//职位类别
    post_nature: '05',//工作性质
    position_salary: '',//月薪范围(字典)
    education: '',//学历要求
    location: {//工作地址
      region: ''//,//区域代码
      //coordinates:[],//坐标
      //address:''//详细地址
    },//工作城市
    intent_count: null,
    majors: [],//专业要求
    skills: [],//技能标签
    responsibility: '',//岗位职责(职位描述)
    preferment: [],//晋升路径
    effect_time: '',//截止日期
    work_address: '',
    month_count: '0',
    living_allowance: '',// 生活补助
    work_experience: '',// 工作年限
    t_endTime: '',
    t_path: null
  };

  //薪资数据
  public salaryData = [
    { "code": "01", "text": "1K以下" },
    { "code": "02", "text": "1K-2k" },
    { "code": "03", "text": "2k-4k" },
    { "code": "04", "text": "4k-6k" },
    { "code": "05", "text": "6k-8k" },
    { "code": "06", "text": "8k-10k" },
    { "code": "07", "text": "10k-15k" },
    { "code": "08", "text": "15k-25k" },
    { "code": "09", "text": "25k以上" }
  ];
  //薪资数据
  public salaryData02 = [
    { "code": "03", "text": "2k-4k" },
    { "code": "04", "text": "4k-6k" },
    { "code": "05", "text": "6k-8k" },
    { "code": "06", "text": "8k-10k" },
    { "code": "07", "text": "10k-15k" },
    { "code": "08", "text": "15k-25k" },
    { "code": "09", "text": "25k以上" }
  ];
  public work_experienceArr = []
  public publishPosition = "发布职位";
  public isSend = false;//是否点击了发送按钮
  public childTitle = '选择专业';
  public childTwoTitle = '选择职位类别';
  public childThreeTitle = '选择职位类别';
  public path = [{ code: 1, text: '' }, { code: 2, text: '' }, { code: 3, text: '' }];//当前需填写晋升职位数量
  public positionid = '';//职位id
  public isWidthSkill = false;//是否获取推荐技能标签
  public position: any;//职位详情
  public positionEndTime: any;//职位到期时间（提醒）
  public endTime = "";//职位到期时间
  public schoolList = [];//学校列表
  public isShowPublishModel = false;
  public isConfirm = true;
  public arr: any;//学校列表

  public showSeleteType = false
  public showjianxiModel = false
  public ruturnPage = false
  public isShowVip = true
  public probationApply = {
    state: 0,
    stateText: '',
    remark: ''
  }
  public uploadApply = {
    filename: '',
    file: '',
    link: ''
  }
  public post_nature = [
    { 'text': '社招', 'code': '01' },
    { 'text': '校招', 'code': '05' },
    { 'text': '实习', 'code': '03' },
    { 'text': '见习', 'code': '06' }]

  // 是否展示缓存中的职位弹窗
  public isShowLocalPosition = false


  constructor(public dataApi: DataApi,
    public local: Local,
    private router: Router,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    public opera: Opera,
    public formatDate: FormatDate,
    public events: Events,
    public toastr: ToastrService) {
    this.config = Config.getInstance();//实例化config
    activatedRoute.queryParams.subscribe(queryParams => {
      console.log('queryParams', queryParams);
      if (queryParams.ruturnPage) {
        this.ruturnPage = true;
      }
      if (queryParams.id) {
        this.positionid = queryParams.id;
        this.local.set(Constants.positionid, this.positionid);
        this.publishPosition = "编辑职位";
        this.getPostDetails();//根据职位positionid获取职位详情
      }
    })
    //判断本地存储，当前是否是刷新状态
    if (!this.positionid) {
      if (this.local.get(Constants.positionid)) {
        this.publishPosition = "编辑职位";
        this.positionid = this.local.get(Constants.positionid);
        this.getPostDetails();//根据职位positionid获取职位详情
      } else {
        let jsonStr = this.local.get('pushPositionData')
        if (jsonStr) {
          console.log('有缓存数据',)
        } else {
          this.showSeleteType = true
        }

        //创建职位的时候赋值默认职位结束时间
        // this.model.effect_time = this.formatDate.formatDate(this.formatDate.addDays(new Date(),60));
      }
    }
  }

  ngOnInit() {

    this.opera.initConfig('position', 'position-manage');
    this.getSkillList();//获取技能标签字典
    this.getRegionList();//获取地区字典
    this.getPositionType();//获取职位类别字典
    this.getMajorList();//获取专业字典
    this.getEducationList();//获取学历列表信息
    this.getPlatformApply();
    this.getPost_nature();
    let that = this;

    let nowData = new Date();
    let year = nowData.getFullYear();
    let month = nowData.getMonth() + 1;
    let day = nowData.getDate() + 14;

    let jsonStr = this.local.get('pushPositionData')

    let nowData2 = new Date();
    if (!this.positionid) {

      //非会员 通过活动发布职位 3天
      let department = this.dataApi.getLocalDepartment();

      // if(!department || !department.member_info || (department && department.member_info && department.member_info.product_code =='member_welfare')){
      //   var newendTime=new Date((nowData2.getTime()/1000+(86400* 3))*1000);//增加n天后的日期
      //   that.endTime = newendTime.getFullYear()+'-'+(newendTime.getMonth()+1)+'-'+newendTime.getDate();
      //   return
      // }

      if (jsonStr) {
        this.isShowLocalPosition = true
        console.log('有缓存数据',)
      } else {
        // var newendTime=new Date((nowData2.getTime()/1000+(86400* 31))*1000);//增加n天后的日期
        var newendTime = moment(nowData2).add(60, 'd')
        // this.endTime = newendTime.getFullYear()+'-'+(newendTime.getMonth()+1)+'-'+newendTime.getDate()
        this.endTime = newendTime.format('YYYY-MM-DD')
      }
    }

    var resultDate = new Date((nowData2.getTime() / 1000 + (86400 * 180)) * 1000);//增加n天后的日期
    let year1 = resultDate.getFullYear();
    let month1 = resultDate.getMonth() + 1;
    let day1 = resultDate.getDate();
    this.positionEndTime = resultDate;
    let t1 = new Date().getTime()
    console.log('职位日期', t1);
    laydate.render({
      elem: '#effect-time',
      // type: 'datetime',
      min: year + "-" + month + '-' + day
      , max: year1 + "-" + month1 + '-' + day1,
      done: function (value, date) {
        console.log(value, date);
        that.endTime = value;
        if (that.endTime) {
          that.model.t_endTime = that.endTime
        }
        that.savePositionData()
      }
    })

  }
  publishSuc() {
    this.isShowPublishModel = false;
    this.router.navigateByUrl('/home/position-manage');
  }
  goRouter(n) {
    //this.opera.scrollTop();
    this.router.navigate(['home/' + n]);
  }
  //根据职位positionid获取职位详情
  getPostDetails() {
    this.opera.loading();
    this.dataApi.getPostDetails(this.positionid).then((res: any) => {
      console.log('获取职位详情', res);
      //console.log(res);
      this.position = res;

      this.initEditorPosition(res);
      this.opera.loadiss();
    }).catch((error) => {
      //console.log(error);
      this.opera.loadiss();
    });
  }
  modifyName() {
    this.model.name = '';
  }
  // 发布成功发提醒
  publishSuccess() {
    this.opera.scrollTop();//页面滚动函数
    if (this.local.get('department')) {
      this.arr = JSON.parse(this.local.get('department'));
      if (this.arr.auth) {
        if (this.arr.auth.length > 3) {
          for (let i = 0; i < 3; i++) {
            this.schoolList.push(this.arr.auth[i].college_name);
            this.schoolList.join(',')
          }
        } else {
          for (let i = 0; i < this.arr.auth.length; i++) {
            this.schoolList.push(this.arr.auth[i].college_name);
            this.schoolList.join(',')
          }
        }
      }
    }

    this.opera.dialog.open(ConfirmsComponent, {
      data: {
        title: '发布成功',
        message: `<div style="text-align: center">
        <p>您的职位将会被发布至以下合作高校审核队列</p>
        <p>${this.schoolList}等${this.arr.auth.length}所高校</p>
        </div>
        `,
        trueText: '知道了',
        letB: true,
        falseText: ''
      }
    }).afterClosed().subscribe(() => {
      this.opera.scrollTrue();
      this.router.navigateByUrl('/home/position-manage');
      this.opera.getDepartment(); //更新企业剩余可发布职位数量
    });
  }
  //编辑职位初始化
  initEditorPosition(_position) {

    this.model.name = _position.name;//职位名称
    this.model.post_type = _position.post_type;//职位类别
    this.model.post_nature = _position.post_nature;//工作性质
    this.model.position_salary = _position.position_salary;//月薪范围(字典)
    this.model.type = _position.post_nature
    this.model.post_nature = _position.post_nature
    this.model.education = _position.education;//学历要求
    this.model.intent_count = _position.intent_count;//学历要求
    this.endTime = _position.effect_time.substr(0, 10);
    this.model.month_count = _position.month_count;// 实习时间：
    this.model.work_address = _position.work_address;// 见习单位：
    // this.model.living_allowance =  _position.living_allowance;// 生活补助
    this.model.work_experience = _position.work_experience;// 生活补助

    //工作地址
    if (_position.location) {
      if (_position.location.region) {
        this.model.location.region = _position.location.region;
      }
    }
    //专业要求
    if (_position.majors) {
      if (_position.majors.length > 0) {
        _position.majors.forEach((val) => {
          this.model.majors.push(val.code);
        });
      }
    }
    //技能标签
    if (_position.skills) {
      if (_position.skills.length > 0) {
        _position.skills.forEach((val) => {
          this.model.skills.push(val.code);
        });
      }
    }
    //晋升路径
    if (_position.preferment) {
      if (_position.preferment.length > 0) {
        let index = 0;
        for (let val of _position.preferment) {
          index = _position.preferment.indexOf(val);
          if (index > 2) {
            let p = { code: index + 1, text: val }
            this.path.push(p);
          } else {
            this.path[index].text = val;
          }
        }
      }
    } else {
      this.model.preferment = [];
    }
    this.model.responsibility = _position.responsibility;//岗位职责(职位描述)
    // this.model.effect_time = this.formatDate.formatDate(new Date(_position.effect_time));//职位到期时间
    console.log('pathpathpathpath', this.path)
  }

  //添加晋升职位
  addPosition() {
    if (this.path.length > 4) {
      this.toastr.warning('晋升职位最多添加5个');
      return;
    }
    this.opera._hmtPush("职位管理", "发布职位 ", "晋升路径");

    let number = this.path.length + 1;
    let p = { code: number, text: '' }
    this.path.push(p);
  }

  //验证宣讲会主题文字格式
  checkName(text) {
    if (!text) { return }

    let filter = /^[a-zA-Z\u4e00-\u9fa5\d_/]+$/;
    if (!filter.test(text)) {
      this.toastr.warning("职位名称只能输入汉字或字母");
      this.model.name = '';
      return;
    }
    this.dataApi.isSensitive({ value: text }).then((res: any) => {
      this.opera.loadiss();
      //console.log(res)
      if (res.contain) {
        this.toastr.warning("职位名称含有敏感字符");
        this.model.name = '';
      }
    }).catch((error) => {
      //console.log(error);
      this.opera.loadiss();
    });
    this.savePositionData()
  }

  //获取学历列表信息
  getEducationList() {
    this.dataApi.getDictDate('education').then((res: any) => {
      this.dataApi.setEducationCode(res);
      this.opera.loadiss();
    }).catch((error) => {
      //console.log(error);
      this.opera.loadiss();
    });
  }

  //获取技能标签字典
  getSkillList() {
    if (this.dataApi.getSkillCode()) {
      this.config.skill = this.dataApi.getSkillCode();
    } else {
      //获取技能字典
      this.opera.loading();
      this.dataApi.getDictDate('skill').then((res: any) => {
        // //console.log(res);
        this.dataApi.setSkillCode(res);
        this.opera.loadiss();
      }).catch((error) => {
        //console.log(error);
        this.opera.loadiss();
      });
    }
  }

  //获取地区字典
  getRegionList() {
    // if(this.dataApi.getRegionCode()){
    //   this.config.region = this.dataApi.getRegionCode();
    // }else{
    //   //获取地区字典
    //   this.opera.loading();
    //   this.dataApi.getDictDate('region').then((res:any)=>{
    //     this.dataApi.setRegionCode(res);
    //     this.opera.loadiss();
    //   }).catch((error)=>{
    //     //console.log(error);
    //     this.opera.loadiss();
    //   });
    // }
    this.dataApi.getRegionCode_dic((data) => {
      this.config.region = data;
    })

  }


  //获取职位类别列表
  getPositionType() {
    if (this.dataApi.getPostTypeCode()) {
      this.config.post_type = this.dataApi.getPostTypeCode();
    } else {
      //获取职位类别字典
      this.opera.loading();
      this.dataApi.getDictDate('post_type').then((res: any) => {
        // //console.log(res);
        this.dataApi.setPostTypeCode(res);
        this.opera.loadiss();
      }).catch((error) => {
        //console.log(error);
        this.opera.loadiss();
      });
    }
  }


  //获取专业列表信息
  getMajorList() {
    if (this.dataApi.getMajorCode()) {
      this.config.major = this.dataApi.getMajorCode();
      this.config.major.push({
        code: "8080",
        parentcode: "0",
        parenttext: null,
        text: "不限",
        level: 1,
        order: 1234,
        relation_code: "",
        relation_desc: ""
      })
      this.config.major.push({
        code: "808080",
        parentcode: "8080",
        parenttext: null,
        text: "不限专业",
        level: 2,
        order: 1235,
        relation_code: "",
        relation_desc: ""
      })
    } else {
      this.opera.loading();
      this.dataApi.getDictDate('major').then((res: any) => {
        //console.log("获取专业列表-----college-resume");
        //console.log(res);
        this.opera.loadiss();
        this.config.major = res;
        this.dataApi.setMajorCode(res);
        this.config.major.push({
          code: "8080",
          parentcode: "0",
          parenttext: null,
          text: "不限",
          level: 1,
          order: 1234,
          relation_code: "",
          relation_desc: ""
        })
        this.config.major.push({
          code: "808080",
          parentcode: "8080",
          parenttext: null,
          text: "不限专业",
          level: 2,
          order: 1235,
          relation_code: "",
          relation_desc: ""
        })
      }).catch((error) => {
        //console.log(error);
        this.opera.loadiss();
      });
    }
  }

  //去选择职位类别
  goPositionType() {
    if(this.positionid) return;
    this.opera._hmtPush("职位管理", "发布职位 ", "职位类别");
    this.modelTwoShow = true;
    this.childTwoTitle = "选择职位类别";
    this.opera.scrollTop();//页面滚动
    //调用子组件函数
    this.child2.initData(this.model.post_type, this.config.post_type, this.childTwoTitle);
  }

  //接收子组件返回数据
  ModelTwodismiss(event) {
    this.modelTwoShow = false;
    this.opera.scrollTrue();
    if (event) {
      // //console.log("接收子组件返回数据");
      // //console.log(event);
      this.model.post_type = event.code;
      this.model.name = event.text;
      this.getRecommendList(this.model.post_type);//获得推荐技能标签
      // if(this.childTwoTitle == "选择职位类别"){
      //   this.model.skills = event;
      // }else{
      //   this.model.majors = event;
      // }
      this.savePositionData()
    }
  }

  //打开查看模板
  goTemplate() {
    if (!this.model.post_type) {
      this.toastr.warning("请先选择职位类别");
      return;
    } else {
      this.modelFourShow = true;
      this.opera.scrollTop();//页面滚动
      this.child4.getTemplate(this.model.post_type);
    }

  }

  //接收子组件返回数据
  ModelFourdismiss(event) {
    console.log(event);
    this.modelFourShow = false;
    this.opera.scrollTrue();
    if (event) {
      // //console.log("接收子组件返回数据");
      // //console.log(event);
      this.model.responsibility = event;
    }
  }

  //推荐标签
  getRecommendList(data) {
    let model = {
      source_dict_type: 'post_type',
      source_code: data,
      result_dict_type: 'skill'
    }
    this.model.skills = [];
    this.opera.loading();
    this.dataApi.getRecommendList(model).then((res: any) => {
      this.isWidthSkill = true;
      //console.log("获取推荐技能标签------------------------------------------positionskills");
      //console.log(res);
      let n = 0;
      for (let val of res.contrast_list) {
        if (n > 5) {
          return;
        }
        if (val.selected) {
          n++;
          this.model.skills.push(val.code);
        }
      }
      this.opera.loadiss();
    }).catch((error) => {
      //console.log(error);
      this.opera.loadiss();
    })
  }

  //去选择工作城市
  goCityCode() {
    this.modelThreeShow = true;
    this.childThreeTitle = "选择工作城市";
    this.opera.scrollTop();//页面滚动
    //调用子组件函数
    this.child3.initData(this.model.location.region, this.config.region, this.childThreeTitle);
  }

  //接收子组件返回数据
  ModelThreedismiss(event) {
    this.modelThreeShow = false;
    this.opera.scrollTrue();
    if (event) {
      // //console.log("接收子组件返回数据");
      // //console.log(event);
      this.model.location.region = event.code;
      this.savePositionData()
    }
  }


  // 监听招聘人数
  recruits() {
    if (this.model.intent_count >= 50) {
      let message = `确定此职位招聘${this.model.intent_count}人`
      let fn2 = () => { this.model.intent_count = 10, this.savePositionData() };
      this.opera.customTips('提示', message, '确定', '取消', '', fn2);
    }
    this.savePositionData()
    if (this.model.intent_count <= 0) {
      if (this.model.type === '03') {
        this.toastr.warning('接收人数填写错误')
      }
      else if (this.model.type === '06') {
        this.toastr.warning('拟接收人数填写错误')
      }
      else {
        this.toastr.warning('招聘人数填写错误')
      }
      setTimeout(() => {
        this.model.intent_count = '';
        this.savePositionData()
      }, 300)
    }
  }


  //选择意向专业
  goMajor() {
    this.opera._hmtPush("职位管理", "发布职位 ", "专业要求");
    console.log('this.model.majors', this.model.majors);
    this.modelShow = true;
    this.childTitle = "选择专业";
    this.opera.scrollTop();//页面滚动
    //调用子组件函数
    this.child.initData(this.model.majors, this.config.major, 5, this.childTitle);
  }

  //接收子组件返回数据
  Modeldismiss(event) {
    this.modelShow = false;
    this.opera.scrollTrue();
    if (event) {
      if (this.childTitle == "选择技能标签") {
        this.model.skills = event;
        this.isWidthSkill = false;
      } else {
        this.model.majors = event;
      }
      this.savePositionData()
    }
  }

  //选择技能标签
  goSkill() {
    this.opera._hmtPush("职位管理", "发布职位 ", "技能标签");

    if (!this.model.post_type) {
      this.toastr.warning("请先选择职位类别");
      return;
    }
    this.modelShow = true;
    this.childTitle = "选择技能标签";
    this.opera.scrollTop();//页面滚动
    //调用子组件函数
    this.child.initData(this.model.skills, this.config.skill, 6, this.childTitle);
  }

  //职位下线
  goExpired() {
    this.opera.loading();
    this.dataApi.managePositionExpired(this.positionid).then((res: any) => {
      //console.log(res);
      this.opera.loadiss();
      setTimeout(() => {
        this.toastr.success('下线成功');
        this.router.navigateByUrl('/home/position-manage');
      }, 600);
      this.opera.setPositionListByExpired(this.positionid);//更新在期职位本地存储
    }).catch((error) => {
      //console.log(error);
      this.opera.loadiss();
      setTimeout(() => {
        this.toastr.error(error);
      }, 600);

    });
  }

  //职位重发检查剩余可发布职位数量
  goResend() {
    let d;
    d = this.dataApi.getLocalDepartment();
    if (d.quota.position == 0) {
      this.opera.loading();
      this.dataApi.getDepartment().then((res: any) => {
        this.opera.loadiss();
        this.dataApi.setLocalDepartment(res);
        if (res.quota.position == 0) {
          this.toastr.warning("发布职位数量已达上限");
          return;
        } else {
          this.goResendPosition();
        }
      }).catch((error) => {
        this.opera.loadiss();
        this.toastr.error(error);
      });
    } else {
      this.goResendPosition();
    }
  }

  //职位重发
  goResendPosition() {
    this.opera.loading();
    this.dataApi.managePositionResend(this.positionid).then((res: any) => {
      //console.log(res);
      this.opera.loadiss();
      setTimeout(() => {
        this.toastr.success('重发成功');
        this.router.navigateByUrl('/home/position-manage');
      }, 600);
      this.opera.setPositionListByRelease();//更新在期职位本地存储
    }).catch((error) => {
      //console.log(error);
      this.opera.loadiss();
      setTimeout(() => {
        if (error.Errcode == '2005') {//配额超限
          this.toastr.warning('发布职位数量已达上限');
        } else {
          this.toastr.error(error);
        }
      }, 600);
    });
  }
  //发布职位
  createPosition() {
    console.log('发布职位', this.model)
    this.opera._hmtPush("职位管理", "发布职位 ", "发布");

    this.isSend = true;
    if (!this.endTime) {
      this.toastr.warning('请选择职位到期时间');
      return;
    }
    let t1 = new Date().getTime();
    let LongTime = 13 * 24 * 60 * 60 * 1000
    t1 = t1 + LongTime
    let t2 = new Date(this.endTime).getTime();
    let t3 = new Date(this.positionEndTime).getTime();
    if (t1 > t2) {
      this.toastr.warning("职位在期时间不得小于14天");
      // this.endTime='';
      return;
    } else if (t2 > t3) {
      this.toastr.warning("职位在期时间不得大于180天");
      // this.endTime='';
      return;
    }
    let hour = new Date().getHours();
    let minute = new Date().getMinutes();
    let second = new Date().getSeconds();
    let hour1, minute1, second1;
    if (hour < 10) {
      hour1 = '0' + hour;
    } else {
      hour1 = String(hour);
    }
    if (minute < 10) {
      minute1 = '0' + minute;
    } else {
      minute1 = String(minute);
    }
    if (second < 10) {
      second1 = '0' + second;
    } else {
      second1 = String(second);
    }
    this.model.effect_time = this.endTime + 'T' + hour1 + ':' + minute1 + ':' + second1 + '+08:00';
    console.log("职位到期时间");
    console.log(this.model.effect_time);

    if (!this.model.name) {
      this.toastr.warning('请完善职位名称');
      return;
    }
    if (!this.model.post_type) {
      this.toastr.warning('请选择职位类别');
      return;
    }
    if (!this.model.position_salary && this.model.post_nature != '06') {
      this.toastr.warning(this.model.post_nature == '03' ? '请选择报酬范围' : "请选择薪资范围");
      return;
    }

    //请填写生活补助
    if (this.model.post_nature == '06' && !this.model.position_salary) {
      this.toastr.warning('请填写生活补助');
      return;
    }
    if ((this.model.month_count == '0' || this.model.month_count == "" || this.model.month_count == null) && (this.model.post_nature == '06' || this.model.post_nature == '03')) {
      this.toastr.warning(this.model.post_nature == '06' ? '请填写见习月数' : '请填写实习时间');
      return;
    }

    if ((!Number.isInteger(this.model.month_count)) && (this.model.post_nature == '06' || this.model.post_nature == '03')) {
      this.toastr.warning(this.model.post_nature == '06' ? '见习月数必须为整数' : '实习时间必须为整数');
      return;
    }
    
    if (!this.model.work_address && this.model.post_nature == '06') {
      this.toastr.warning('请填写单位地址');
      return;
    }
    if (!this.model.work_experience && this.model.post_nature == '01') {
      this.toastr.warning('请选择工作年限');
      return;
    }

    if (!this.model.education) {
      this.toastr.warning('请完选择学历要求');
      return;
    }

    if (this.model.majors.length == 0) {
      this.toastr.warning('请选择专业');
      return;
    }

    if (this.model.majors.length > 0) {
      console.log(this.model.majors)
      this.model.majors.map((item) => {
        if (item == '808080') {
          this.model.majors = [];
        }
      })
    }
    if (!this.model.location.region) {
      this.toastr.warning('请选择工作地点');
      return;
    }
    if (!this.model.intent_count) {
      this.toastr.warning('请输入招聘人数');
      return;
    }
    
    if (this.model.intent_count <= 0) {
      this.toastr.warning('招聘人数填写错误')
      return;
    }
    if (!Number.isInteger(this.model.intent_count)) {
      this.toastr.warning('请输入招聘人数必须为整数');
      return;
    }
    if (!this.model.responsibility) {
      this.toastr.warning('请输入岗位职责(职位描述)');
      return;
    }


    this.model.preferment = [];
    for (let val of this.path) {
      if (val.text) {
        this.model.preferment.push(val.text);
      }
    }

    if (this.model.preferment.length > 0) {
      console.log('晋升路径', this.model.preferment);
      let nary = this.model.preferment.slice().sort();
      for (let i = 0; i < this.model.preferment.length; i++) {
        if (nary[i] == nary[i + 1]) {
          this.toastr.warning('重复的晋升路径：' + nary[i])
          return;
        }
      }
    }


    //console.log(this.model);
    //判断否是编辑职位
    let that = this;
    this.opera.loading();
    if (this.positionid) {
      this.dataApi.updatePosition(this.positionid, this.model).then((res: any) => {
        //console.log(res);
        this.opera.loadiss();
        this.opera.setPositionListByRelease();//更新在期职位本地存储

        setTimeout(() => {
          that.events.publish('addPositionSuccess');
          that.toastr.success('修改成功');
          that.router.navigateByUrl('/home/position-manage');
        }, 200);
      }).catch((error) => {
        //console.log(error);
        this.opera.loadiss();
        setTimeout(() => {
          this.toastr.error(error);
        }, 200);
      });
    } else {
      this.dataApi.createPosition(this.model).then((res: any) => {
        this.opera.loadiss();
        this.local.remove('pushPositionData')
        this.toastr.success('发布成功');
        this.events.publish('addPositionSuccess');
        this.opera._hmtPush("职位管理", "发布职位 ", "发布成功");
        this.opera.setPositionListByRelease();//更新在期职位本地存储

        if (this.ruturnPage) {
          this.dialog.open(ConfirmComponent, {
            data: {
              title: '提示',
              message: '是否返回双选会继续报名？',
              trueText: '是',
              falseText: '否'
            }
          }).afterClosed().subscribe((isConfirmed) => {
            //console.log(isConfirmed,'点击确定');
            if (isConfirmed) {
              history.go(-1);
              return
            } else {
              this.router.navigate(['home/position-manage'], {
                queryParams: {
                  isShow: true
                }
              });
            }
          });
        } else {
          this.router.navigate(['home/position-manage'], {
            queryParams: {
              isShow: true
            }
          });
        }


      }).catch((error) => {
        //console.log(error);
        this.opera.loadiss();
        setTimeout(() => {
          if (error.Errcode == '2005') {//配额超限
            this.toastr.warning('在线职位数量已达上限');
          } else {
            this.toastr.error(error);
          }
        }, 200);
      });
    }
  }
  //选择发布职位的工作性质
  seletePositonType(type, code) {

    this.model.post_nature = code;
    this.model.type = type;

  }
  seletePositonTypeCommit() {
    if (this.model.type == '') {
      return
    }
    this.showSeleteType = false;
    if (this.model.type != '06') {
      this.savePositionData()
    }

    if (this.model.type == '06') {
      if (this.probationApply.state == 1) {
        this.toastr.warning('您提交的审核正在审批中，请耐心等待审核结果')
        this.showSeleteType = true
        return
      }
      if (this.probationApply.state == 2) {
        this.showSeleteType = false;
        this.savePositionData()
        return
      }
      if (this.probationApply.state != 2) {
        this.showSeleteType = false;
        this.showjianxiModel = true
      }
    }
  }
  closeSeleteType() {
    this.showSeleteType = false
    this.router.navigate(['home/position-manage'], {
    });
  }
  closejianxiModel() {
    this.showjianxiModel = false
    this.showSeleteType = true
  }
  //获取企业申请的数据，实习审核的状态
  getPlatformApply() {
    this.dataApi.getAuthenticationProbation().then((res: any) => {
      console.log('getAuthenticationProbation', res);
      if (res) {
        this.probationApply.state = res.status;
        if (res.status == 1) {
          this.probationApply.stateText = '审核中'
        }
        if (res.status == 3) {
          this.probationApply.stateText = '审核未通过'
          this.probationApply.remark = res.remark

          this.uploadApply.file = res.file
          this.uploadApply.link = res.link
        }
      }
    }).catch((error) => {
      //
    });
  }
  //附件图片
  previewMilkPic(event) {

    let imgData = event.target.files[0]; //获取file对象
    console.log('imgData', imgData)
    if (window.FileReader) {
      var reader = new FileReader();
      reader.readAsDataURL(imgData);
      var that = this;
      reader.onloadend = function (e: any) {
        that.uploadFile(reader.result, imgData.name);
      };
    }
  }
  uploadFile(data, name) {
    let imageData = this.dataApi.formatFile(data);
    console.log('data', imageData)

    let filename = ''
    if (name) {
      let arr = filename.split('.')
      filename = arr[0]
    }
    let filedata = {
      extension: name,
      filename: filename ? filename : '见习资质',
      base64: imageData
    }
    // let loader = this.tip.presentLoading('图片保存中，请稍等...');
    this.dataApi.uploadDepartmentFile(filedata).then((res: any) => {
      // loader.dismiss();
      this.uploadApply.file = res.link
      this.uploadApply.filename = res.filename
    }).catch((error) => {
      // loader.dismiss();
      this.toastr.error("文件格式不正确，请上传word/pdf格式的图片");
    })
  }
  //申请见习
  applyJianxi() {
    if (!this.uploadApply.file) {
      this.toastr.warning('请上传确认文件')
      return
    }
    if (!this.uploadApply.link) {
      this.toastr.warning('请填写公示链接')
      return
    }
    this.opera.loading()
    if (this.probationApply.state == 3) {
      this.dataApi.postAuthenticationProbationUpdate(this.uploadApply).then((res: any) => {
        this.opera.loadiss();
        this.toastr.success('申请成功，请等待审核')
        this.showjianxiModel = false
        this.showSeleteType = true
        this.getPlatformApply()
      }).catch((error) => {
        this.opera.loadiss();
        setTimeout(() => {
          this.toastr.error(error);
        }, 200);
      })

      return
    }
    this.dataApi.postAuthenticationProbation(this.uploadApply).then((res: any) => {
      this.opera.loadiss();
      this.toastr.success('申请成功，请等待审核')
      this.showjianxiModel = false
      this.showSeleteType = true
      this.getPlatformApply()
    }).catch((error) => {
      this.opera.loadiss();
      setTimeout(() => {
        this.toastr.error(error);
      }, 200);
    })
  }
  //获取工作性质
  getPost_nature() {
    this.dataApi.getDictDate('post_nature').then((res: any) => {
      console.log('getPost_nature', res);
    }).catch((error) => {
    })
    this.dataApi.getDictDate('work_experience').then((res: any) => {
      console.log('work_experience', res);
      if (res) {
        this.work_experienceArr = res
      }
    }).catch((error) => {
    })
  }
  //保存未完成的职位信息
  savePositionData() {
    if (this.endTime) {
      this.model.t_endTime = this.endTime
    }
    if (!this.positionid) {
      this.local.set('pushPositionData', JSON.stringify(this.model))
    }
  }
  userLocalData(use) {
    this.isShowLocalPosition = false
    if (use) {
      let jsonStr = this.local.get('pushPositionData')
      if (jsonStr) {
        this.model = JSON.parse(jsonStr)
        if (this.model.t_endTime) {
          this.endTime = this.model.t_endTime
        }
        if (this.model.t_path) {
          this.path = this.model.t_path
        }
      }
    } else {
      this.local.remove('pushPositionData')
      this.showSeleteType = true
      let nowData2 = new Date();
      // var newendTime=new Date((nowData2.getTime()/1000+(86400* 31))*1000);//增加n天后的日期
      var newendTime = moment(nowData2).add(60, 'd')
      // this.endTime = newendTime.getFullYear()+'-'+(newendTime.getMonth()+1)+'-'+newendTime.getDate()
      this.endTime = newendTime.format('YYYY-MM-DD')
    }
  }
  inputChangeSaveData() {
    console.log('inputChangeSaveData', this.model)
    this.model.t_path = this.path
    this.savePositionData()
  }
  deletePath(item, index) {
    if (this.path.length == 1) {
      return;
    }
    this.path = this.path.filter((item, i) => { // item为数组当前的元素
      return index != i;
    })
    console.log('deletePath', item, index)
  }
  gotoBack() {
    this.events.publish('position-push-cancal-scrollTop');
    this.opera.goNewRouter('position-manage')
  }

}
