<!--简历管理=>收到的-->

<div style="background-color:transparent;" class="company-block" *ngIf="isShow">
    <router-outlet></router-outlet>
    <app-resume-label-model #resumeLabelModel [isShow]="addResumeLabelmodelShow" (modelHidden)="addResumeLabelmodeHidden($event)"></app-resume-label-model>
    <app-model-position-notice #noticeDelivery [isShow]="modelShow" (onVoted)="Modeldismiss($event)">
    </app-model-position-notice>
    <!-- 新版本 -->
    <div class="resume-container">
        <!-- 头部 -->
        <div class="header">
            <div class="secondNav">
                <div class="item active">收到的<span></span></div>
                <div class="item" (click)="nav02()">收藏的</div>
                <div class="item" (click)="nav03()">极速电话联系记录</div>
                <div class="item" (click)="nav04()">职位推送记录</div>
                <div class="item" (click)="nav05()">校招活动邀请记录</div>
                <div class="item" (click)="nav06()">空中宣讲会推广记录</div>
            </div>
            <div class="banner" style="margin-bottom:0">
                <div class="layui-carousel" id="test1" *ngIf="bannerList.length>0">
                    <div carousel-item>
                        <div *ngFor="let n of bannerList;let i = index;">
                            <img class="swiper-slide bannerImage" (click)="bannerClick(n)" id={{i}} src="{{n.image_url}}" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- 非会员 -->
            <div class="novipTip" *ngIf="!isShowMemberMoudel">
                <div class="text">非会员收到的简历数量有限，加入会员，接收更多人才简历</div>
                <div class="join joinHover" (click)="product('','contactMe')">加入会员</div>
            </div>
            <div class="search">
                <div class="formBox">
                    <div class="top">
                        <div class="tabs">
                            <div class="item" [class.purple]="model.status==''" (click)="changeStatus('')">
                                全部简历({{tabCount.total}})<span></span></div>
                            <div class="item fn" [class.purple]="model.status=='posted'" (click)="changeStatus('posted')">未查看({{tabCount.posted}})<span></span></div>
                            <div class="item fn" [class.purple]="model.status=='viewed'" (click)="changeStatus('viewed')">已查看({{tabCount.viewed}})<span></span></div>
                            <div class="item fn" [class.purple]="model.status=='invited'" (click)="changeStatus('invited')">已邀约({{tabCount.invited}})<span></span></div>
                            <div class="item fn" [class.purple]="model.status=='interviewed'" (click)="changeStatus('interviewed')">已面试({{tabCount.interviewed}})<span></span></div>
                            <div class="item fn" [class.purple]="model.status=='interested'" (click)="changeStatus('interested')">感兴趣({{tabCount.interested}})<span></span></div>
                            <div class="item fn" [class.purple]="model.status=='improper'" (click)="changeStatus('improper')">不合适({{tabCount.improper}})<span></span></div>
                        </div>
                        <div>
                            <select class="filtrate_row_select activeBg" name="position" id="position" [(ngModel)]="model.positionid" (ngModelChange)="getSendResumeListSearch($event)">
                                <option value=''>全部招聘职位</option>
                                <option *ngFor="let a of positionList" [value]="a.positionid">{{a.name}}
                                    <span *ngIf="a.position_status == 'delete'">(已删除)</span>
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="bottom" *ngIf="openMoreFilt">
                        <div class="cell">
                            <select class="filtrate_row_select_gray activeBg" style="margin-right: 8px" name="position" id="sex" [(ngModel)]="model.gender" (ngModelChange)="getSendResumeListSearch($event)" autocomplete="off">
                                <option value=''>性别不限</option>
                                <option value="01">男</option>
                                <option value="02">女</option>
                            </select>
                        </div>
                        <div class="cell">
                            <select class="filtrate_row_select_gray" style="margin-right: 8px" name="position" [(ngModel)]="model.education" (ngModelChange)="getSendResumeListSearch($event)" autocomplete="off">
                                <option value=''>学历不限</option>
                                <option *ngFor="let a of educationList" [value]="a.code">{{a.text}}</option>
                            </select>
                        </div>
                        <div class="cell">
                            <select class="filtrate_row_select_gray" style="margin-right: 8px" name="position" id="regionOne" [(ngModel)]="regionOne" (ngModelChange)="regionOneChange($event)" autocomplete="off">
                                <option value=''>籍贯不限</option>
                                <option value="{{n.code}}" *ngFor="let n of area1">{{n.text}}</option>
                            </select>
                            <select class="filtrate_row_select_gray" *ngIf="regionOne" style="margin-right: 8px" name="position" id="regionOne2" [(ngModel)]="model.native_place" (ngModelChange)="getSendResumeListSearch($event)" autocomplete="off">
                                <option value=''>请选择籍贯</option>
                                <option value="{{n.code}}" *ngFor="let n of area3">{{n.text}}</option>
                            </select>
                        </div>
                        <div class="cell">
                            <select class="filtrate_row_select_gray" style="margin-right: 8px" name="position" id="grade" [(ngModel)]="model.year" (ngModelChange)="getSendResumeListSearch($event)" autocomplete="off">
                                <option value=''>届别不限</option>
                                <option *ngFor="let city of jiebieList" [value]="city.code">{{city.text}}</option>
                            </select>
                        </div>

                        <div class="cell ">
                            <select class="filtrate_row_select_gray active" style="margin-right: 8px" name="mrid" id="mrid" [(ngModel)]="model.mrid" (ngModelChange)="getSendResumeListSearch($event)" autocomplete="off">
                                <option *ngFor="let mrid of mrdList" [value]="mrid.Id">{{mrid.Name}}</option>
                            </select>
                        </div>

                    </div>
                </div>
                <div class="more" [class.active]="openMoreFilt" (click)="openMoreFiltClick()">
                    {{openMoreFilt?'收起':'更多'}}<img src="/assets/images/rights/moreIcon.png"></div>
            </div>
        </div>

        <div class="searchBox">
            <div class="left">
                <div class="or" (click)="AllSeleteClick()">
                    <div class="choice" [class.active]='isAllSelete'><span *ngIf="isAllSelete"></span></div>
                    全选
                </div>
                <div class="downResume" *ngIf="downLoadArr.length == 0">
                    <img src="/assets/images/rights/downResumeicon.png"> 批量导出简历
                </div>
                <div class="downResume active" (click)="downLoadChips()" *ngIf="downLoadArr.length>0">
                    <img src="/assets/images/rights/downResumeiconActive.png"> 批量导出简历
                </div>
                <div class="markImproper" *ngIf="model.status!=='improper'&&downLoadArr.length == 0">
                    <img src="/assets/images/v3/cancelIcon.png"> 批量标记不合适
                </div>
                <div class="markImproper active" (click)="markImproper()" *ngIf="model.status!=='improper'&&downLoadArr.length>0">
                    <img src="/assets/images/v3/cancelIconActive.png"> 批量标记不合适
                </div>
            </div>
            <div class="right">
                <div class="input">
                    <img src="/assets/images/rights/search.png">
                    <input type="text" [(ngModel)]="model.keywords" (ngModelChange)="changeinput($event)" placeholder="搜索学校名称">
                </div>
                <div class="input">
                    <img src="/assets/images/rights/search.png">
                    <input placeholder="搜索简历ID" (ngModelChange)="changeinput($event)" [(ngModel)]="model.resume_number" />
                </div>
            </div>
        </div>

        <!-- 简历列表 -->
        <div class="resume-list">
            <div class="resume-list-item" *ngFor="let n of resumeList;let i = index;" (click)="goDetails(n,i)">
                <div class="choice" (click)="cellClickDown(n,$event)" [class.active]='n.selete'><span *ngIf="n.selete"></span></div>
                <div class="main">
                    <div class="introduce">
                        <div class="logo"><img *ngIf="n.logo" [src]="n.logo+'&imageView2/1/w/55/h/55'"></div>
                        <div class="text">
                            <div class="name">{{n.talent_name | clearSpace}}<span *ngIf="n.feedback_status == 'viewed' || n.feedback_status == 'interested' || n.feedback_status == 'improper'">已查看</span>
                            </div>
                            <div class="college" *ngIf='n.graduate_university'>{{n.graduate_university}}</div>
                            <div class="major">{{n.major_text}} | {{n.education_text}} | {{n.year==0?"往届":n.year+'届'}}
                            </div>
                        </div>
                    </div>
                    <div class="lable">
                        <span *ngFor="let youshi of n.skills | slice:0:4;let i = index;">{{youshi.text}}</span>
                    </div>
                    <div class="intention">
                        <span>求职意向：</span>{{n.yixiang}}
                    </div>
                    <div class="bottom">
                        <div class="bottom-item"><img src="/assets/images/rights/positionicon.png">投递职位：<span>{{n.position_name}}</span></div>
                        <div class="bottom-item"><img src="/assets/images/rights/time.png">投递时间：{{n.add_time | date:'yyyy-MM-dd HH:mm:ss'}}</div>
                    </div>

                </div>
                <!-- 收藏 -->
                <div class="collection">
                    <img (click)="shouCang($event,n)" *ngIf="n.isfollow_reusme" src="/assets/images/rights/Collection.png">
                    <img (click)="shouCang($event,n)" *ngIf="!n.isfollow_reusme" src="/assets/images/rights/noCollection.png">
                    <span (click)="shouCang($event,n)" style="color: #9A9A9A;font-size: 12px;cursor: pointer;margin-top: 5px">{{n.isfollow_reusme?'已收藏':'收藏'}}</span>
                </div>
            </div>

        </div>

        <div class="none-block-empty" *ngIf="countList==0">
            <img src="assets/images/rights/empty.png">
            <p>暂未找到相关简历</p>
        </div>

        <!-- 分页部分 -->
        <div class='paging-block' *ngIf="countList>0">
            <router-outlet></router-outlet>
            <select class="paging-block-filtrate_row_select_gray" placeholder="请选择" [(ngModel)]="eachPageCount" (ngModelChange)="getpageCount($event)">
                <option value="10">10条/页</option>
                <option value="20">20条/页</option>
                <option value="50">50条/页</option>
            </select>
            <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
        </div>

    </div>

</div>

<!--热力值弹窗-->
<p-dialog id="aboutHot" [contentStyle]="{'padding':'0',border:0,'border-radius':'10px'}" [dismissableMask]="false" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':480,'height':250,'minHeight':250}" [modal]="true"
    [showHeader]="false" [(visible)]="seleteDownLoadTypeModel.show">
    <div style="width: 480px;height: 250px;background-color: white;overflow: hidden;position: relative" class="downloadBg">
        <img src="../../assets/images/down_close.png" class="close_model" (click)="closeDownLoad(false)" />
        <div class="down_title flex_l" *ngIf="seleteDownLoadTypeModel.step==1"><img src="../../assets/images/my_com.png" />保存到我的电脑</div>
        <div class="down_title flex_l" *ngIf="seleteDownLoadTypeModel.step==2"><img src="../../assets/images/down_check.png" />文件准备成功</div>
        <div class="downType" style="vertical-align: top;" *ngIf="seleteDownLoadTypeModel.step==1">
            <div>文件格式：</div>
            <img *ngIf="seleteDownLoadTypeModel.currentType!=='word'" (click)="downLoadChange('word')" src="../../assets/images/d_pdf.png" />
            <img *ngIf="seleteDownLoadTypeModel.currentType=='word'" (click)="downLoadChange('word')" src="../../assets/images/d_pdf_s.png" />

            <!--<img *ngIf="seleteDownLoadTypeModel.currentType!=='excel'" (click)="downLoadChange('excel')" src="../../assets/images/d_excel.png" />-->
            <!--<img *ngIf="seleteDownLoadTypeModel.currentType=='excel'" (click)="downLoadChange('excel')" src="../../assets/images/d_excel_s.png" />-->


        </div>

        <div class="downType flex_l" style="vertical-align: top;" *ngIf="seleteDownLoadTypeModel.step==2">
            <div style="color: #B2B2BD;width: 100%;height: 100px">点击下载按钮开始下载</div>
        </div>

        <!--<div class="downLoadChips" *ngIf="seleteDownLoadTypeModel.step==1">只支持word2003以上版本</div>-->
        <div class="commitChipsDown flex_r" *ngIf="seleteDownLoadTypeModel.step==1">
            <div class="commitChipsDown_none" (click)="closeDownLoad(false)">取消</div>
            <div class="commitChipsDown_commit" (click)="closeDownLoad(true)">保存</div>
        </div>
        <div class="commitChipsDown flex_r" *ngIf="seleteDownLoadTypeModel.step==2">
            <div class="commitChipsDown_none" (click)="closeDownLoad(false)">取消</div>
            <div class="commitChipsDown_commit" (click)="closeDownLoadHTTP()">下载</div>
        </div>
    </div>
</p-dialog>


<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0,'border-radius':'6px'}" [(visible)]="resumeNumberShow" (onHide)="resumeNumberHidden()" [modal]=true>
    <div class="resumeNumberShow">
        <div class="rightBg">
            <img src="/assets/images/v3/rightBg.png">
        </div>
        <div class="closIcon" (click)="resumeNumberHidden()"><img src="/assets/images/rights/productModelClos.png">
        </div>
        <div class="main">
            在您的会员到期之后，您一共收到<span>{{resumeNumberCount}}</span>份简历，您要查看这部分学生简历信息，请开通会员
            <div class="p3" (click)="product('','contactMe')">开通会员</div>
        </div>
    </div>
</p-dialog>

<!-- 产品介绍/请校招顾问联系我 -->
<app-product-model #productModel [isShow]="isproductShowModel" (modelHidden)="productModelHidden($event)"></app-product-model>