import {Injectable} from '@angular/core';
import {isEmpty} from "rxjs/operators";
import { DataApi } from './dataApi';
/**
 * 这是一个单例模式的config，用于共享全局变量
 */
@Injectable()
export class Config {

    //   配置信息(本地)
    // public api = 'http://192.168.0.120:801';          // http请求前缀
    // public agentApi = 'http://192.168.0.120:802';     // http请求前缀
    // public clientId = '5d9d56d72e8f034ad7d0e5e5';      // clientId  （客户端ID）
    // public cdapi = 'http://cdapi.test.goworkla.cn';
    // public roomApi = "https://oi.test.goworkla.cn";
    // public roomsignalrUrl = "https://oi.test.goworkla.cn/hub";

    // 配置信息（test）
    // public api = 'https://webapi4.test.goworkla.cn';      // http请求前缀
    // public agentApi = 'https://agent4.test.goworkla.cn';   // http请求前缀
    // public clientId = '5d9d56d72e8f034ad7d0e5e5';         // clientId  （客户端ID）
    // public cdapi = 'https://cdapi.test.goworkla.cn';
    // //视频面试(测试环境)
    // public roomApi = "https://oi.test.goworkla.cn";
    // public roomsignalrUrl = "https://oi.test.goworkla.cn/hub";

    // 配置信息（test）
    // public api = 'https://webapi.test.goworkla.cn';      // http请求前缀
    // public agentApi = 'https://agent.test.goworkla.cn';   // http请求前缀
    // public clientId = '5d9d56d72e8f034ad7d0e5e5';         // clientId  （客户端ID）
    // public cdapi = 'https://cdapi.test.goworkla.cn'; //视频面试(测试环境)
    // public roomApi = "https://oi.test.goworkla.cn";
    // public roomsignalrUrl = "https://oi.test.goworkla.cn/hub";

    // *****************************************************************

    // 配置信息(正式环境)
    public api = "https://webapinet6.goworkla.cn";        // http请求前缀
    // public agentApi = "https://agent.goworkla.cn";     // http请求前缀
    public agentApi = 'https://agentnew.goworkla.cn';  // http请求前缀
    public clientId = '5d9d56d72e8f034ad7d0e5e5';      // clientId  （客户端ID）
    public cdapi = 'https://cdapi.goworkla.cn';
    public roomApi = "https://oi.goworkla.cn";//视频面试(正式环境)
    public roomsignalrUrl = "https://oi.goworkla.cn/hub";


    // public urmApi = "https://urm.test.goworkla.cn";
    public urmApi = "https://urm.goworkla.cn";

     // public liveSocket = "http://liveapi.test.goworkla.cn/chat";
    public liveSocket = "http://liveapi.goworkla.cn/chat";

    public GetWXCode = 'http://wx.goworkla.cn/mp/makeercode?sceneStr='; // 注册第三步和首页获取微信公众
    public PC_Version = "3.0.1";

    public isActiveNav ='home';   // 判断当前激活的导航栏
    public CustomerServicePhone ='400-661-1396'; // 客服电话
    public websiteRecord = 'Copyright © ' + this.getYear() + ' GoWork.la. All Rights Reserved. Ver  ' + this.PC_Version; //网站备案号
    public current_system  = "b_pro";

    public isNavActive = 'home';                //当前激活的导航栏
    public isNavChildActive = '';               //当前激活的子导航栏git branch
    public isLogin = false;                     //当前是否是登陆页面（用于改变顶部导航栏）
    public isGuide = false;                     //当前是否是引导页面（用于改变顶部导航栏）
    public loading:boolean = false;
    //是否显示lodar动画
    public isGoCollege = false;                 //当前是否激活校园招聘导航
    public isGoChat = false;                   //当前是否激活消息中心导航
    public isGoPosition = false;              //当前是否激活招聘管理导航
    public isGoCompany = false;               //当前是否激活企业中心导航

    public childNavs = [];

    public productCode = '';               // 购买产品代码
    public recharge_category = '';         // 单品类型
    public gotoVipListType = '';           // 跳转到会员列表默认选中的会员类型
    public recharge_type:any;              // 单品类型相同的区分
    public batchList = [];                 // 简历批量邀投列表
    public memberInfo:any;                 // 简历批量邀投列表
    public collectionTips:boolean = false  // 收藏弹窗提醒

    public firstLogin=false;           //判断是否是第一次登陆（展示引导）
    public isFirstPublish=false;       //判断是否是第一次发布职位
    public isByCollege=false;          //判断是否是从就业信息网跳转
    public byCollegeLogo='';           //从就业信息网跳转高校logo
    public byCollegeName='';           //从就业信息网跳转高校名称
    public token: string = '';         //如果已经登录，存放token，请和localstorage.get('token')同步（令牌）
    public refresh_token : any;        //刷新token
    public accountid: string = '';     //accountid（账号ID）
    public employerid: string = '';    //employerid
    public loginUser: any;             //如果已经登录，存放登录用户信息，请和本地存储保持同步
    public loginName: any;             //账号，方便出错登录
    public loginEmail: any;            //邮箱账号，方便出错登录
    public timeDatadepartment: any;    //企业信息 department
    public lastIdentity: any;          //最后一次认证信息
    public lastCollegeIdentity: any;   //最后一次学校认证信息
    public lastIdentityNavSatus: any;          //导航上的工作啦认证状态

    public positionList = [];          //在期职位列表(进入首页就需要用到)
    public posiList = {list:[],count:0};          //在期职位列表(进入首页就需要用到)
    public chatsMsgList = [];          //聊天记录本地存储
    public chatsList :any;             //会话列表本地存储
    public jmList = [];                //极光聊天记录（不再存入本地只做全局变量）
    public jmLoginCount=1;             //极光登陆次数
    public isJmLogin=false;            //极光登陆失败时，是否重新登陆
    public unReadNum = 0;              //tabs页面未读消息数量
    public NavunReadNum = 0;              //tabs页面未读消息数量

    public isSetRootLogin = true;      //出错后是否允许跳到登录页面(默认true可以跳入)
    public isAskResume = false;        //是否索要简历
    public addressComponents:'1601';      //定位地址----城市
    public addressComponentCity:any;   //定位地址----县区
    // public ShareUrl="http://www.papastyle.cn/wap/";//分享地址线下
    public ShareUrl="https://main.goworkla.cn/share/";//分享地址线上
    public industry=[];               //行业字典
    public post_type=[];              //职位类别
    public major=[];                 //专业字典
    public skill=[];                 //技能字典
    public region=[];                //地区字典
    public education=[];             //学历字典
    public common=[];               //常用语字典
    public commonJun=[];            //小秘书常用语字典
    public school_type=[];          //高校分类字典（只做全局变量）
    public school_nature=[];        //高校性质字典（只做全局变量）
    public school_label=[];        //高校特色字典（只做全局变量）
    public school=[];            //高校字典（只做全局变量）
    public label=[];             //企业标签字典
    public strength=[];          //企业实力标签
    public employer_type=[];     //企业性质字典
    public scale=[];             //企业规模字典
    public organization_type=[]; //组织机构类型字典
    public sucEndTime=""; //组织机构类型字典

    public ProductListCode={
      activity_invitation_code:'', //活动邀请
      sms_bulkinvitation_code:'', // 职位推送
      position_refresh_code:'', //职位刷新
      voice_call_code:'', //极速电话联系
      notice_student_sms_code:'', //极速电话联系
    };

    static instance: Config;           //初始化config
    static isCreating: Boolean = false;//判断config是否已初始化
    public searchStatus = false;//是否开通高级搜索权益
    public searchendTime:any;//认证结束时间

    public is618 =false;//是否是618活动
    public startData618 ="2020-6-15";//618活动开始日期
    public endData618 ="2020-6-18 23:59:59";//618活动结束日期 要多加一天

    public d1 = "宣讲会双选会短信邀约，学生到场率翻倍";//单品活动通知包描述
    public d1618 = "无需进校张贴海报、短信提醒忘不了、学生到场率更高";//618单品活动通知包描述
    public d2 ="学生在线回复不及时？快试试极速短信联系";//单品极速联系包描述
    public d2618 ="沟通更及时、提醒更有效、反馈早知道";//618单品极速联系包描述


    //单品的类型
    public sms_activity = 'sms_activity'; //活动通知
    public voice_call = 'voice_call'; //极速电话联系
    public sms_bulkinvitation = 'sms_bulkinvitation'; //职位推送
    public position_refresh = 'position_refresh'; //职位刷新
    public position_top='position_top'; //职位置顶
    public payTimer = null;

    //企业信息是否被冻结
    public is_frozen :Boolean=false;

    //未读消息列表，单聊，通知页面的消息列表
    public msgList=[];
    //未读消息总数
    public tasktodo = 0;

    //企业的校招顾问，在home页面获取，赋值
    public departmentSelf:any;
    public departmentSelf_ex = "(添加请备注“用人单位名称+姓名”)";

    //是否是谷歌内核
    public chrome_core = false;

    //是否刷新tpken
    public isRefTokening = false;

    //工作啦校园招聘解决方案
    public gwl_activity_School_url = 'http://images.goworkla.cn/emailpage/gwlActivity/index.html';

    //会员升级的表单链接
    public member_shengji_url = "https://jinshuju.net/f/DcMApY";
    //会员续费的表单链接
    public member_xufei_url = "https://jinshuju.net/f/DcMApY";
    department: any;

    //极速电话联系升级
    public callSafeUpgrade = false;

    public isScreenSmallBox = false;//当前屏幕分辨率小的提示，在登录也检测屏幕分辨率

    //是否显示网络请求400，500 错误
  public isShowHTTPErrModel = false;

  public isShowPayModel= false;

  //会员到期时间
  public memberEndTime = 60;

  //待支付订单 数量
  public ordertoPayCount = 0;


  //alipay_qr
  public  alipay_key = "alipay_pc_direct";


  public payVipHistoryPage = null;
  public userInitiativePayName = null;

  public isShowPro_tanchuang = true;

  //是否打开体验版会员的弹窗
  public isOpenExperienceMember = false

  //公众号扫码关注
  public wxCodeURL = "";

  //记录用户是否是体验版会员
  public isMember_trial = false

  //是展示开启支付系统，支付产品
  public isShowPaySystem = false;

  public userisBindWx = false; // 用户是够绑定微信
  public isMember_welfare = false;// 用户是否为公益会员


  constructor() {
          if (!Config.isCreating) {
              throw new Error("You can't call new in Config Singleton instance!");
          }
    }

    static getInstance() {
        if (Config.instance == null) {
            Config.isCreating = true;
            Config.instance = new Config();
            Config.isCreating = false;
        }
        return Config.instance;
    }

  getYear() {
    let newYear = new  Date();
    return newYear.getFullYear();
  }
  public jiebie = [];
  
  getJiebie(){
    // console.log('dataapi',DataApi.getCircles());
    if(this.jiebie.length>0) {
      return this.jiebie;
    }
    let arr = [{text:'届别不限',code:'0'}];
    let newYear = new  Date();
    let current = this.getYear();

    //如果当前的月份小于9月，则向前取一年
    // 2023年6月8 李威辛修改
    if((newYear.getMonth()+1)<9) {
      current-=1;
    }
    let value = current+1;
    let value1 = current;
    let value2 = current-1;
    arr.push({text:value+"届",code:value+""});
    arr.push({text:value1+"届",code:value1+""});
    arr.push({text:"往届",code:value2+""});
    this.jiebie = arr;
    return arr;
  }


}

//export * from './config';